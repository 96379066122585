import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import { theme } from "./src/components/GrowthTheme"
import "./src/global.css"

export const wrapRootElement = ({ element }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <React.Fragment>
        {element}
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </React.Fragment>
    </MuiThemeProvider>
  )
}
