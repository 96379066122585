import { createMuiTheme } from '@material-ui/core/styles'

let styles = {
  typography: {
    useNextVariants: true
  },
  fontFamily: 'Merriweather, Open Sans, Roboto',
  palette: {
    background: {
      default: '#fff'
    },
    bluePrimary: '#2196F3',
    blueSecondary: '#1976D2',
    blueLight: '#e1f1ff',
    blueGrayMedium: '#37474F',
    blueGrayDark: '#37474F',
    blueGrayLight: '#90A4AE',
    blueDeep: '#041C2F',
    grayVeryLight: '#F0F0F0',
    grayLight: '#ECEFF1',
    grayMedium: '#757575',
    grayDark: '#383838',
    grayVeryDark: '#212121',
    black: '#000000',
    redPrimary: '#FF5252',
    greenPrimary: '#00E676',
    greenSecondary: '#4CAF50',
    white: '#FFFFFF',
    gold: '#FFC107',
    darkPink: '#EF2D56',
    orangePrimary: '#ED7D3A',
    seoScoreRed: '#f44336',
    seoScoreOrange: '#ff9800',
    seoScoreYellow: '#ffeb3b',
    seoScoreLightGreen: '#8bc34a',
    seoScoreGreen: '#4caf50'
  },

  overrides: {
    MuiCardHeader: {
      title: {
        fontSize: 16,
        fontWeight: 500
      }
    },
    MuiTypography: {
      colorPrimary: {
        color: 'blueGrayDark'
      },
      colorSecondary: {
        color: 'white'
      },
      colorTextPrimary: {
        color: '#2196F3'
      },
      colorTextSecondary: {
        color: 'white'
      },
      body1: {
        fontFamily: 'Open Sans',
        fontSize: '1em',
        lineHeight: '26px',
        color: 'rgba(55, 71, 79, 1)'
      },
      body2: {
        fontFamily: 'Open Sans',
        fontSize: '0.875em',
        color: 'rgba(55, 71, 79, 1)'
      },
      caption: {
        fontFamily: 'Open Sans',
        fontSize: '0.750em',
        color: 'rgba(55, 71, 79, 1)'
      },
      subtitle1: {
        fontFamily: 'Open Sans',
        color: 'rgba(55, 71, 79, 1)'
      },
      h1: {
        fontFamily: 'Open Sans',
        fontSize: '2.125em',
        fontWeight: 500,
        lineHeight: 1.25,
        color: 'rgba(55, 71, 79, 1)'
      },
      h2: {
        fontFamily: 'Merriweather',
        fontSize: '2.125em',
        fontWeight: 700,
        letterSpacing: 0.75,
        lineHeight: 1.25,
        color: 'rgba(55, 71, 79, 1)'
      },
      h3: {
        fontFamily: 'Open Sans',
        fontSize: '2.125em',
        fontWeight: 500,
        lineHeight: 1.25,
        color: 'rgba(55, 71, 79, 1)'
      },
      h4: {
        fontFamily: 'Open Sans',
        fontSize: '1.375em',
        fontWeight: 500,
        lineHeight: 1.5,
        letterSpacing: 0.75,
        color: 'rgba(55, 71, 79, 1)'
      },
      h5: {
        fontFamily: 'Open Sans',
        fontSize: '1em',
        fontWeight: 700,
        lineHeight: 1.5,
        letterSpacing: 0.75,
        color: 'rgba(55, 71, 79, 1)',
        margin: '40px 0 8px 0'
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent'
      }
    },
    MuiButton: {
      root: {
        fontFamily: 'Open Sans',
        fontWeight: 700,
        borderRadius: 2,
        maxWidth: 264,
        minHeight: 56,
        fontSize: '1em',
        '& hover': {
          border: '2px solid !important'
        }
      },
      textPrimary: {
        color: '#2196F3',
      },
      outlinedPrimary: {
        color: '#2196F3',
        border: '3px solid',
        '&:hover': {
          border: '3px solid #2196F3 !important'
        }
      },
      outlinedSecondary: {
        color: '#FF5252',
        border: '3px solid',
        '&:hover': {
          border: '3px solid #FF5252 !important'
        }
      },
      containedPrimary: {
        backgroundColor: '#2196F3',
        '&:hover': {
          backgroundColor: '#1976D2'
        }
      },
      containedSecondary: {
        backgroundColor: '#FF5252',
        color: 'white'
      },
      sizeSmall: {
        padding: '8px 24px',
        maxWidth: 264,
        minHeight: 48,
        fontSize: '1em'
      },
      sizeLarge: {
        padding: '8px 24px',
        maxWidth: 264,
        minHeight: 64,
        fontSize: '1.125rem'
      }
    },
    MuiSvgIcon: {
      colorPrimary: {
        fill: 'black'
      },
      colorSecondary: {
        fill: 'white'
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#2196F3'
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: '#2196F3',
        height: 45,
        '& button': {
          width: 30,
          height: 30,
          background: 'none',
          margin: '0 5px',
          '& span': {
            background: 'none',
            color: '#FFF',
            marginTop: '-4px'
          }
        }
      },
      transitionContainer: {
        '& p': {
          color: 'white'
        }
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#2196F3'
      }
    },
    MuiPickersDay: {
      '&$selected': {
        backgroundColor: '#2196F3'
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#2196F3'
      },
      thumb: {
        border: '14px solid #2196F3'
      }
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#2196F3'
      }
    }
  },
  hcpLogo: {
    fill: 'white !important',
    width: '250px !important',
    height: '40px !important',
    margin: '20px 24px'
  }
}

export const theme = createMuiTheme(styles)
